<template>
  <main>
    <div class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{sourceData.uniID_part}}</el-breadcrumb-item>
        </el-breadcrumb>
        <section class="aside-info">
          <div class="aside-name">{{sourceData.title}}</div>
          <div class="aside-sub-name">{{sourceData.uniID_part}}</div>
          <div class="aside-time">更新時間： {{formatDate(sourceData.datetime_update)}}</div>
        </section>
        <section>
          <ul class="list list--with-icon">
            <li class="item">
              <font-awesome-icon :icon="[ 'fas', 'folder' ]" />
              <div class="item__box">
                {{sourceData.type}}
              </div>
            </li>
            <li class="item">
              <font-awesome-icon :icon="[ 'fas', 'clock' ]" />
              <div class="item__box">
                {{sourceData.date}}
              </div>
            </li>
            <li class="item">
              <font-awesome-icon :icon="[ 'fas', 'map-marker-alt' ]" />
              <div class="item__box">
                {{sourceData.court}}
              </div>
            </li>
          </ul>
        </section>
        <section>
          <div class="separate-title">查詢公司相關分析</div>
          <div class="aside-filter">
            <div class="aside-filter-container">
              <!-- 提及公司 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{VERDICTS_HIGHLIGHT_ITEM_DICT.company}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedCompanyValue">
                    <el-checkbox
                      class="one-level"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedCompanyOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedCompanyOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
              <!-- 提及代表人 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{VERDICTS_HIGHLIGHT_ITEM_DICT.representative}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedRepresentativeValue">
                    <el-checkbox
                      class="one-level"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedRepresentativeOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedRepresentativeOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
              <!-- 提及董事 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{VERDICTS_HIGHLIGHT_ITEM_DICT.shareHolders}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedShareHoldersValue">
                    <el-checkbox
                      class="one-level"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedShareHoldersOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedShareHoldersOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
              <!-- 提及經理人 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{VERDICTS_HIGHLIGHT_ITEM_DICT.managers}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedShareHoldersValue">
                    <el-checkbox
                      class="one-level"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedManagersOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedManagersOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
            </div>
            <!-- <div class="aside-filter-footer">
              <el-button type="primary">
                <font-awesome-icon :icon="[ 'fas', 'check' ]" />
                醒目標註
              </el-button>
            </div> -->
          </div>
        </section>
      </div>
      <div class="container">
        <section>
          <h4 class="section-title">判決全文</h4>
          <div
            class="law"
            v-loading="isLoading"
            v-html="contentHtml">
          </div>
          <!-- <HighLightContent
            :article="sourceData.content"
            :keywordList="keywordList"
            :colorClasses="colorClasses"
            v-if="sourceData.content">
          </HighLightContent> -->
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as apis from '@/apis/index.ts'
import routerService from '@/utils/routerService.js'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
// import HighLightContent from '@/components/HighLightContent/HighLightContent.vue'


// let article = 'aaa|aa統一超|商1bbbccccc\nccc|商2ccccc統|cc\ncc|一超商3dddddddeeee\neeffffffffgggg\ngghhhhhhiiiiiijj\njjjkkkkkk'

// //let keyword1 = '統一超商股份有限公司'
// let keyword1 = '統一超商'
function replaceText (originArticle, keyword, attrText) {
  let totalMatchCount = 0

  // 頭尾的keyword取代（有換行的keyword）
  let { article, matchCount } = replaceLeftRightText(originArticle, keyword, attrText)
  totalMatchCount = matchCount

  // 完整keyword取代 
  const reg = new RegExp(keyword, 'g')
  const newText = `<span all ${attrText}>${keyword}</span>`
  article = article.replace(reg, newText)

  let textArr = article.split(keyword)
  totalMatchCount = totalMatchCount + textArr.length - 1 // 比對到的關鍵字數量

  return {
    article,
    matchCount: totalMatchCount
  }

  function replaceLeftRightText (article, keyword, attrText) {
    let matchCount = 0 // 比對到的數量
    // 建立所有的關鍵字組合比對清單
    const indexData = returnIndexData(keyword)

    // -- 開始尋找 --
    let lines = article.split('\n')
    lines.forEach((line, lineIndex) => {
      indexData.forEach(({rightText, rightSelector, rightSelector2, leftText, leftSelector, leftSelector2}, i) => {
        // -- 尋找右關鍵字 case1 --
        let texts = line.split(rightSelector)
        // 第一行有找到右關鍵字
        if (texts.length > 1) {
          let verticleLineCount = texts[0].split('│').length - 1
          // 非最後一行
          if (lineIndex < lines.length) {
            let nextLine = lines[lineIndex + 1]
            // indexData.forEach(({leftText, leftSelector, leftSelector2}, i) => {
              // -- 尋找左關鍵字 case1 --
              let nextTexts = nextLine.split(leftSelector)
              // 第二行有取到左關鍵字
              if (nextTexts.length > 1) {
                let nextVerticleLineCount = nextTexts[0].split('│').length - 1
                // 第一行和第二行取到的關鍵字左邊的"|"數量一樣//（因第二行關鍵字已包含"|"所以需相差1）
                if (verticleLineCount - nextVerticleLineCount == 1) {
                  // 取代字串
                  lines[lineIndex] = line.replace(rightSelector, `<span ${attrText}>${rightText}</span>│`)
                  lines[lineIndex + 1] = nextLine.replace(leftSelector, `│<span ${attrText}>${leftText}</span>`)
                  // 累計比對數量
                  matchCount++
                }
              }
              // -- 尋找左關鍵字 case2 --
              let nextTexts2 = nextLine.split(leftSelector2)
              // 第二行有取到左關鍵字
              if (nextTexts2.length > 1) {
                let nextVerticleLineCount = nextTexts2[0].split('│').length - 1
                // 第一行和第二行取到的關鍵字左邊的"|"數量一樣//（因第二行關鍵字已包含"|"所以需相差1）
                if (verticleLineCount - nextVerticleLineCount == 1) {
                  // 取代字串
                  lines[lineIndex] = line.replace(rightSelector2, `<span ${attrText}>${rightText}</span>│`)
                  lines[lineIndex + 1] = nextLine.replace(leftSelector2, `│  <span ${attrText}>${leftText}</span>`)
                  // 累計比對數量
                  matchCount++
                }
              }
            // })
          }
        }
        // -- 尋找右關鍵字 case2 --
        let lineTrim = line.trim()
        let cutRightText = lineTrim.substr(lineTrim.length - rightText.length, rightText.length)
        // 第一行有找到右關鍵字
        if (cutRightText === rightText) {
          let nextLine = lines[lineIndex + 1]
          let nextLineTrim = nextLine.trim()
          let cutLiftText = nextLineTrim.substr(0, leftText.length)
          // 第二行有取到左關鍵字
          if (cutLiftText === leftText) {
            // 取代字串
            let indexOfText = line.indexOf(rightText)
            lines[lineIndex] = line.substr(0, indexOfText) + `<span ${attrText}>${rightText}</span>`
            lines[lineIndex + 1] = nextLine.replace(leftText, `<span ${attrText}>${leftText}</span>`)
            // 累計比對數量
            matchCount++
          }
        }
      })
      
    })
    return {
      article: lines.join('\n'),
      matchCount
    }

    // 建立所有的關鍵字組合比對清單
    function returnIndexData (keyword) {
      let indexData = []
      let keywordLength = keyword.length
      let leftCropMaxLength = keywordLength - 1
      for (let n = leftCropMaxLength; n > 0; n--) {
        let rightText = keyword.substring(0, n)
        let leftText = keyword.substring(n, keywordLength)
        indexData.push({
          rightText,
          leftText,
          rightSelector: rightText + '│', // 右側截取索引
          leftSelector: '│' + leftText, // 左側截取索引
          rightSelector2: rightText + '│', // 右側截取索引
          leftSelector2: '│  ' + leftText // 左側截取索引
          // leftSelector: leftText // 左側截取索引
        })
      }
      return indexData
    }
  }
}

// console.log(newArticle)

export default {
  props: ['uniID'],
  // components: {
  //   HighLightContent
  // },
  data () {
    return {
      VERDICTS_HIGHLIGHT_ITEM_DICT: codes.VERDICTS_HIGHLIGHT_ITEM_DICT,
      isLoading: true,
      contentHtml: '',
      colorClass1: 'color-primary',
      colorClass2: 'color-danger',
      colorClass3: 'color-warning',
      colorClass4: 'color-success',
      // relatedCompany: [],
      // relatedRepresentative: [],
      // relatedShareHolders: [],
      // relatedManagers: [],
      sourceData: {
        uniID_part: '',
        date: '',
        court: '',
        type: '',
        related_company: null,
        title: '',
        datetime_update: '',
        content: ''
      },
      relatedCompanyOptions: [],
      relatedRepresentativeOptions: [],
      relatedShareHoldersOptions: [],
      relatedManagersOptions: [],
      relatedCompanyValue: [],
      relatedRepresentativeValue: [],
      relatedShareHoldersValue: [],
      relatedManagersValue: [],
      // keywordList: [],
      // colorClasses: [this.colorClass1, this.colorClass2, this.colorClass3, this.colorClass4]
    }
  },
  computed: {
    ...mapState([
      'originRouteQuery'
    ]),
  },
  methods: {
    formatDate (date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD')
      } else {
        return '-'
      }
    },
    onChangeOptionValue (isSelect, evt) {
      const key = evt.target.value
      this.toggleHighlight(key, isSelect)
    },
    toggleHighlight (key, isHighlight) {
      const dom = document.querySelectorAll(`[data-value="${key}"]`)
      if (dom) {
        const className = dom[0].getAttribute('data-class')
        if (isHighlight == true) {
          dom.forEach(d => {
            // 開啟
            d.classList.add(className)
            // d.style.cssText = ''
          })
        } else {
          dom.forEach(d => {
            // 關閉
            d.classList.remove(className)
            // d.style.cssText = 'font-family:細明體'
          })
        }
      }
    }
  },
  async created () {
    if (!this.uniID) {
      // 無查詢條件導回首頁
      this.$router.replace({ name: 'home' })
      return
    }
    // -- 取得判決書資料 --
    const result = await apis.verdictsGet({ uniID: this.uniID })
    this.isLoading = false
    // 無判決資料
    // if (!result.payload ||
    // !result.payload.found ||
    // !result.payload.source ||
    // !result.payload.source.content) {
    //   this.$message.error('無判決書資料')
    //   return
    // }
    // 取得資料
    this.sourceData = result.payload.source
    // -- 標記關鍵字 --
    let contentHtml = result.payload.source.content
    if (this.originRouteQuery.rCompany) {
      let keywords = this.originRouteQuery.rCompany.split(',')
      let relatedCompanyOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `company_${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass1}" class="${this.colorClass1}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass1}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedCompanyOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedCompanyOptions = relatedCompanyOptions
      // this.relatedCompanyValue = this.relatedCompanyOptions.map(d => d.value)
    }
    if (this.originRouteQuery.rRepresentative) {
      let keywords = this.originRouteQuery.rRepresentative.split(',')
      let relatedRepresentativeOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `representative_${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass2}" class="${this.colorClass2}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass2}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedRepresentativeOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedRepresentativeOptions = relatedRepresentativeOptions
      // this.relatedRepresentativeValue = this.relatedRepresentativeOptions.map(d => d.value)
    }
    if (this.originRouteQuery.rShareHolders) {
      let keywords = this.originRouteQuery.rShareHolders.split(',')
      let relatedShareHoldersOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `shareHolders_${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass3}" class="${this.colorClass3}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass3}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedShareHoldersOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedShareHoldersOptions = relatedShareHoldersOptions
      // this.relatedShareHoldersValue = this.relatedShareHoldersOptions.map(d => d.value)
    }
    if (this.originRouteQuery.rManagers) {
      let keywords = this.originRouteQuery.rManagers.split(',')
      let relatedManagersOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `managers_${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass4}" class="${this.colorClass4}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass4}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedManagersOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedManagersOptions = relatedManagersOptions
      // this.relatedManagersValue = this.relatedManagersOptions.map(d => d.value)
    }

    // 寫入html
    this.contentHtml = contentHtml
    
    await this.$nextTick()

    // -- 顯示highlight --

    // 預設「提及公司」highlight
    this.relatedCompanyOptions.forEach(d => {
      this.toggleHighlight(d.value, true)
    })
    this.relatedCompanyValue = this.relatedCompanyOptions.map(d => d.value)
    // 根據網址參數highhight
    if (this.originRouteQuery.highlight) {
      const lightlightArr = this.originRouteQuery.highlight.split(',')
      this.relatedRepresentativeValue = []
      this.relatedRepresentativeOptions.forEach(d => {
        if (d.value in lightlightArr) {
          this.toggleHighlight(d.value, true)
          this.relatedRepresentativeValue.push(d.value)
        }
      })
      this.relatedShareHoldersValue = []
      this.relatedShareHoldersOptions.forEach(d => {
        if (d.value in lightlightArr) {
          this.toggleHighlight(d.value, true)
          this.relatedShareHoldersValue.push(d.value)
        }
      })
      this.relatedManagersOptions = []
      this.relatedManagersOptions.forEach(d => {
        if (d.value in lightlightArr) {
          this.toggleHighlight(d.value, true)
          this.relatedManagersOptions.push(d.value)
        }
      })
    }

  },
  watch: {
    '$route.query': {
      handler (q) {
        // 如果網址參數是原始參數的話，重新再導向到短網址
        if (this.$route.query.rCompany
        || this.$route.query.rRepresentative
        || this.$route.query.rShareHolders
        || this.$route.query.rManagers
        || this.$route.query.highlight) {
          routerService('replace', {
            name: this.$route.name,
            params: this.$route.params,
            query: this.$route.query
          })
          return
        }
      },
      immediate: true
    }
  },
}
</script>
